<template>
  <div class="your-reports-wrapper">
    <div class="your-reports">
      <div class="your-reports-main-content">
        <div class="fs-24 t-fs-20 bold-600 mb-2">
          Your Reports
        </div>
        <div class="your-reports-table-wrapper for-mobile-view-on-sof-and-reports">
          <ReportHistoryTable :rows-per-page="10" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import ReportHistoryTable from '@/pages/your-reports/components/ReportHistoryTable'

export default {
  name: 'YourReports',
  components: {
    ReportHistoryTable,
  },
  data() {
    return {}
  },

  methods: {},
}
</script>

<style>
.your-reports-wrapper {
  display: flex;
  justify-content: center;
  padding-top: var(--header-height);
}

.your-reports-wrapper {
}

.your-reports {
  width: 100%;
  min-height: var(--main-content-height);
  display: flex;
  justify-content: center;
}

.your-reports-main-content {
  display: flex;
  flex-direction: column;
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
  padding-top: 24px;
}

.your-reports-table-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media (max-width: 1023px) {
  .your-reports-main-content {
    padding-top: 16px;
  }
}
</style>
