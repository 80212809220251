<template>
  <div class="reports-history-table-wrapper">
    <div
      v-if="loading && !historyData.length"
      class="reports-history-table-empty-wrapper"
    >
      <gl-loader progress-spinner />
    </div>
    <div
      v-if="!loading && !historyData.length"
      class="reports-history-table-empty-wrapper"
    >
      <div class="reports-history-table-empty-text-wrapper">
        You currently have no reports
      </div>
    </div>

    <DataTable
      v-show="historyData.length"
      :always-show-paginator="false"
      class="p-table-groupped p-table-nowrap-data p-table-pale-border"
      :lazy="true"
      :loading="loading"
      paginator
      responsive-layout="scroll"
      :rows="perPage"
      :rows-per-page-options="pagesOptions"
      scroll-height="flex"
      :scrollable="true"
      :total-records="totalRecords"
      :value="historyData"
      @page="onPage($event)"
      @sort="onSort($event)"
    >
      <Column
        
        field="coin"
        header="Coin"
        sortable
      >
        <template #body="slotProps">
          <div class="bold uppercase">
            {{ slotProps.data.coin }}
          </div>
        </template>
      </Column>
      <Column
        field="address"
        header="Address Report"
      >
        <template #body="slotProps">
          <div
            class="ellipsis bold pointer"
            @click="copyAddress(slotProps.data.address)"
          >
            {{ trancateString(slotProps.data.address, 16) || '–' }}
          </div>
        </template>
      </Column>
      <Column
        field="includes"
        header="Type"
      >
        <template #body="slotProps">
          <div class="flex gap-2">
            <GlInfoTag
              v-for="(part, i) in orderType(slotProps.data.includes)"
              :key="i"
              type="info"
            >
              <div class="bold-600 fs-12 capitalize">
                {{ part }}
              </div>
            </GlInfoTag>
          </div>
        </template>
      </Column>
      <Column
        field="createdAt"
        header="First generated"
        sortable
        :styles="{ 'min-width': '130px' }"
      >
        <template #body="slotProps">
          <div>
            <div class="reports-history-table__text-mobile">First generated</div>
            <div class="bold fs-14">
              {{
                slotProps.data.createdAt
                  ? formatDate(
                    new Date(slotProps.data.createdAt),
                    'dd.MM.yyyy HH:mm',
                  )
                  : '–'
              }}
            </div>
          </div>
        </template>
      </Column>

      <Column  
        field="address"
      >
        <template #body="slotProps">
          <div
            class="flex flex-end bold-600 accent-text-link pointer reports-history-table-button-mobile"
            @click="
              viewReport(
                { address: slotProps.data.address, tx: slotProps.data.tx },
                slotProps.data.coin,
              )
            "
          >
            <gl-icon
              class="mr-2"
              :height="20"
              :name="isDesktop ? 'open-link' : 'open-link-white'"
              :width="20"
            />
            View Report
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
//Vuex
import { mapActions } from 'vuex'
// Components
import GlIcon from '@/components/gl-icon'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import GlLoader from '@/components/gl-loader'
import GlInfoTag from '@/components/gl-info-tag'
// Utils
import { formatDate } from '@/utils/format-date'
import { trancateString } from '@/utils/text-formatter'
import { copyText } from '@/utils/copy-text'
import { scrollToSelector } from '@/utils/scroll-to'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  name: 'ReportHistoryTable',
  components: {
    GlIcon,
    DataTable,
    Column,
    GlLoader,
    GlInfoTag,
  },
  mixins: [deviceWidthMixin],
  props: {
    isPaymentHistoryOpen: {
      type: Boolean,
      default: false,
    },
    rowsPerPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      loading: false,
      pagesOptions: [5, 10, 20, 50, 100],
      perPage: 5,
      historyData: [],
      totalRecords: 0,
      lazyParams: {},
      screenWidth: window.innerWidth,
    }
  },
  computed: {
    headerHeight() {
      return getComputedStyle(document.documentElement).getPropertyValue(
        '--header-height',
      )
    },
  },
  mounted() {
    this.perPage = this.rowsPerPage
    this.lazyParams = {
      pageSize: this.perPage,
      pageNumber: 1,
      sort: {},
    }
    this.loadData()
    window.addEventListener('resize', this.updateScreenWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.updateScreenWidth)
  },
  methods: {
    ...mapActions({
      getReportsHistoryList: 'report/getReportsHistoryList',
    }),
    trancateString,
    formatDate,
    copyText,
    scrollToSelector,
    scrollToSelectorItem(wrap, aim) {
      this.scrollToSelector(
        wrap,
        aim,
        parseInt(this.headerHeight),
      )
    },
    async loadData() {
      this.loading = true
      await this.getReportsHistoryList({
        lazyParams: this.lazyParams,
      })
        .then(({ success, data }) => {
          if (!success) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error Message',
              detail: data.data.message,
              life: 3000,
            })
          }
          this.totalRecords = data.pagesTotal * data.pageSize
          this.historyData = data.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    onPage(event) {
      this.scrollToSelectorItem('.main', '.sof-main-content-wrapper', 200)
      this.lazyParams.pageSize = event.rows
      this.lazyParams.pageNumber = event.page + 1
      this.loadData()
    },
    onSort(event) {
      this.lazyParams.sort = {}
      if (event.sortField) {
        this.lazyParams.sort[event.sortField] = event.sortOrder
      }
      this.loadData()
    },
    orderType(array) {
      const desiredOrder = ['owner', 'score', 'type', 'entity', 'full']
      const customSort = (a, b) => {
        return desiredOrder.indexOf(a) - desiredOrder.indexOf(b)
      }
      return array.slice().sort(customSort)
    },
    async viewReport({ address, tx }, coin) {
      await this.$router.push({
        name: 'report',
        query: {
          address,
          tx,
          type: coin,
        },
      })
    },
    async copyAddress(address) {
      this.copyText(address)
        .then(() => {
          this.$toast.add({
            severity: 'info',
            summary: 'Success Message',
            detail: 'Copied!',
            life: 3000,
          })
        })
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    }
  },
}
</script>

<style>
.reports-history-table-wrapper {
  height: 100%;
  padding-bottom: 96px;
}

.reports-history-table-empty-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.reports-history-table-empty-text-wrapper {
  max-width: 440px;
  font-weight: 500;
  text-align: center;
}

.reports-history-table-wrapper
  .p-datatable-responsive-scroll
  > .p-datatable-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* full scroll height */
.reports-history-table-wrapper .p-datatable-flex-scrollable {
  display: block;
}
.p-datatable-flex-scrollable .p-datatable-wrapper {
  height: auto;
  margin-bottom: 16px;
}
.reports-history-table__text-mobile {
  display: none;
}

@media (max-width: 1023px) {
  .for-mobile-view-on-sof-and-reports .p-datatable-table {
    width: 100%;
    table-layout: fixed !important;
  }
  .for-mobile-view-on-sof-and-reports .p-table-groupped.p-datatable-scrollable .p-datatable-thead {
    display: none !important;
  }
  .for-mobile-view-on-sof-and-reports .p-table-groupped .p-datatable-tbody > tr {
    display: flex !important;
    flex-wrap: wrap;
    padding: 16px 16px 0 16px;
    border: 1px solid var(--pale-grey);
    border-radius: 8px;
  }
  .for-mobile-view-on-sof-and-reports .p-table-groupped .p-datatable-tbody > tr + tr {
    margin-top: 16px;
  }
  .for-mobile-view-on-sof-and-reports .p-table-pale-border.p-datatable .p-datatable-tbody > tr > td {
    padding: 0;
    border-bottom: none;
  }
  .for-mobile-view-on-sof-and-reports .p-table-groupped .p-datatable-tbody > tr td:first-child {
    order: 2;
    flex: 0 0 80px;
    text-align: right;
  }
  .for-mobile-view-on-sof-and-reports .p-table-groupped .p-datatable-tbody > tr td:nth-child(2) {
    margin-bottom: 16px;
    order: 1;
    flex: 0 0 calc(100% - 80px);
  }
  .for-mobile-view-on-sof-and-reports .p-table-groupped .p-datatable-tbody > tr td:nth-child(3) {
    flex: 1 0 100%;
  }
  .for-mobile-view-on-sof-and-reports .p-table-groupped .p-datatable-tbody > tr td:nth-child(3) {
    margin-bottom: 24px;
    order: 3;
  }
  .for-mobile-view-on-sof-and-reports .p-table-groupped .p-datatable-tbody > tr td:nth-child(4) {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    order: 4;
  }
  .for-mobile-view-on-sof-and-reports .p-table-groupped .p-datatable-tbody > tr td:nth-child(5) {
    overflow: visible;
    order: 5;
  }
  .for-mobile-view-on-sof-and-reports .reports-history-table-button-mobile {
    align-items: center;
    justify-content: center;
    height: 42px;
    margin-right: -16px;
    background-color: var(--reflex-bluet);
    border-radius: 8px 0 8px 0;
    font-size: 14px;
    color: var(--white);
  }
  .reports-history-table__text-mobile {
    display: block;
    min-width: 100%;
    font-size: 12px;
    line-height: 1;
  }
}
</style>
