<template>
  <div
    class="info-tag"
    :class="{
      'info-tag-warning': type === 'warning',
      'info-tag-success': type === 'success',
      'info-tag-info': type === 'info',
      'info-tag-some': type === 'some',
      'info-tag-failure': type === 'failure',
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String, Object],
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
}
</script>

<style>
.info-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  font-size: 14px;
  border-radius: 37px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  width: fit-content;
}
.info-tag-warning {
  background-color: var(--warning-70);
  color: var(--warning);
}
.info-tag-success {
  background-color: var(--green-75);
  color: var(--green);
}
.info-tag-some {
  background-color: var(--yellow-25);
  color: var(--yellow);
}
.info-tag-failure {
  background-color: var(--red-25);
  color: var(--lipstick);
}
.info-tag-info {
  min-width: auto;
  background: rgba(25, 75, 251, 0.15);
  color: var(--reflex-bluet);
  padding: 8px 12px;
  border-radius: 4px;
}

@media (max-width: 1279px) {
  .info-tag {
    font-size: 12px;
    padding: 6px 8px;
  }
}
</style>
